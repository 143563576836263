import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { changeLoginModal } from "../../../core/store/modals-data/modals-data";
import {
  getAuthorizationStatus,
  getAuthorizationUserId,
} from "../../../core/store/authorization-data/selectors";
import {
  getUser,
  getUserError,
  getUserLoading,
} from "../../../core/store/users-data/selectors";
import { fetchUser } from "../../../core/store/api-actions";
import Loader from "../../simple/loader/loader";

function Profile() {
  const dispatch = useAppDispatch();
  const handleModalOpen = () => {
    dispatch(changeLoginModal({ loginModal: true }));
  };

  const userId = useAppSelector(getAuthorizationUserId);
  const user = useAppSelector(getUser);
  const userLoading = useAppSelector(getUserLoading);
  const userError = useAppSelector(getUserError);
  const loginStatus = useAppSelector(getAuthorizationStatus);

  useEffect(() => {
    if (userId !== undefined) {
      dispatch(fetchUser(Number(userId)));
    }
  }, [dispatch, userId]);

  return (
    <>
      <Loader hidden={!userLoading} color="#ffffff" />
      {/* <div className={`profile ${userLoading || userError ? 'hidden' : ''}`}> РАСКОМЕНТИРОВАТЬ ПРИ ВЫКАТКЕ НА ПРОД И УДАЛИТЬ 33 СТР*/}
      <div
        className={`profile ${userLoading || userError ? "hidden" : "hidden"}`}
      >
        <button
          className={`button profile__button ${loginStatus ? "hidden" : ""}`}
          onClick={handleModalOpen}
        >
          <p className="profile__text">Войти</p>
        </button>
        <Link
          className={`profile__photo ${loginStatus ? "" : "hidden"}`}
          to={AppRoute.PersonalInfoPage.url}
        >
          <img
            className="profile__img"
            src={
              user?.profile?._links?.photo?.href?.includes(
                "placeholder_thumb.svg"
              )
                ? "/images/defaults/person.svg"
                : user?.profile?._links?.photo?.href
            }
            width="32"
            height="32"
            alt={`${user?.profile?.first_name} ${user?.profile?.last_name}`}
          />
        </Link>
      </div>
    </>
  );
}

export default Profile;
